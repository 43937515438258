import { Injectable } from '@angular/core';
import { OktaAuth, Token } from '@okta/okta-auth-js';

import { OktaConstant } from '../constants/okta.constant';

import { OktaRoutes } from '../constants/route.constant';
import { Environment } from '../environment/environment.model';

@Injectable({
  providedIn: 'root',
})
export class OktaPkceAuthService {
  /*********************Properties*********************/
  readonly auth = { validate: true };
  oktaAuth: OktaAuth = null;
  private redirectUrls = {
    login: window.location.origin,
    callback: window.location.origin + OktaRoutes.Callback.Url,
  };
  private pkceConfig = {
    url: '',
    issuer: '',
    clientId: '',
    pkce: true,
    grantType: 'authorization_code',
    redirectUri: '',
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor() {}
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  init(): void {
    const config = Environment.getInstance().oktaConfig;
    Object.mapProperties(this.pkceConfig, config);
    this.pkceConfig.url = config.issuer;
    this.oktaAuth = this.getOktaAuth();
  }

  isAuthCallback(): boolean {
    return window.location.href.startsWith(this.redirectUrls.callback);
  }

  async isAuthenticated(): Promise<boolean> {
    // Checks if there is a current idToken in the TokenManger.
    return this.oktaAuth
      ? !!(await (this.oktaAuth.tokenManager.get(OktaConstant.IdToken) &&
          this.oktaAuth.tokenManager.get(OktaConstant.AccessToken)))
      : true;
  }

  async getToken(tokenName: string = OktaConstant.AccessToken): Promise<Token> {
    return await this.oktaAuth.tokenManager.get(tokenName);
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  initAuthCode(): Promise<any> {
    return this.oktaAuth.token.getWithRedirect({
      responseType: this.pkceConfig.pkce ? 'code' : ['token', 'id_token'],
      scopes: ['openid', 'profile', 'email'],
    });
  }

  async redirect(): Promise<any> {
    return this.oktaAuth.token
      .parseFromUrl()
      .then((tokens) => {
        const { idToken } = tokens.tokens;
        const { accessToken } = tokens.tokens;

        const response = {
          idToken: idToken.value,
          accessToken: accessToken.value,
          hasError: false,
        };

        this.oktaAuth.tokenManager.add(OktaConstant.IdToken, idToken);
        this.oktaAuth.tokenManager.add(OktaConstant.AccessToken, accessToken);

        response.hasError = response.accessToken ? false : true;
        return Promise.resolve(response);
      })
      .catch((error) => {
        error.hasError = true;
        return Promise.resolve(error);
      });
  }

  async signOut(): Promise<void> {
    await this.isAuthenticated().then((oktaAuthenticated) => {
      if (oktaAuthenticated) {
        this.oktaAuth.signOut();
      }

      this.oktaAuth.tokenManager.clear();
      window.localStorage.clear();
      window.sessionStorage.clear();
    });

    setTimeout(() => {
      this.initAuthCode();
    }, 500);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private getOktaAuth(
    redirectUrl: string = this.redirectUrls.callback
  ): OktaAuth {
    if (!OktaAuth.features.isPKCESupported()) {
      this.pkceConfig.pkce = false;
      this.pkceConfig.grantType = '';
    }

    this.pkceConfig.redirectUri = redirectUrl;
    return new OktaAuth(this.pkceConfig);
  }

  /*********************Private Methods*********************/
}
